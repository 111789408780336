import DashboardAccount from "../../Components/DashboardAccount";
import TransactionsList from "../../Components/TransactionsList";
import TransactionsListMobile from "../../Components/TransactionsListMobile";
import Loader from "../../Components/modules/Loader";

import React, { useState } from "react";
import { Link } from "react-router-dom";

import useSWR from "swr";
import EmptyList from "../../Components/EmptyList";
// import Router from "next/router";
import ListLoader from "../../Components/modules/ListLoader";
import { APIClient } from "../../utils/api_helper";
import { Card, CardBody, Container, Row } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import RecentTransactionsList from "./RecentTransactionsList";

const api = new APIClient();

const fetcher = async (...args) => {
  const data = await api.get("/users/user/wallet/");

  return data;
};

export default function Home({ user }) {
  let {
    data: data1,
    error,
    isValidating,
    mutate: mutateWallet,
  } = useSWR(`/users/user/wallet/`, fetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });

  let {
    data: transactionsList,
    error: transactionList_error,
    isValidating: isTransactionListValidating,
    mutate: mutateTransactionList,
  } = useSWR(`/transaction/`, transactionListFetcher, {
    revalidateOnFocus: false, // revalidate when the page gains focus
    revalidateOnReconnect: false, // revalidate when the browser regains network connectivity
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleGoToTransaction = () => {
    localStorage.setItem("currentPage", "Transaction");
    // Router.push("/transactions");
  };

  async function transactionListFetcher(...args) {
    setIsLoading(true);

    const data = await api.get("/transaction/");
    setIsLoading(false);

    console.log(data, "DATAAAA");

    return data;
  }

  console.log(transactionsList, "TRANS__DATA");

  transactionsList = transactionsList?.data?.slice(0, 5);

  const mutateAll = (...mutateFunctions) => {
    mutateTransactionList();
    mutateWallet();

    mutateFunctions.forEach((mutateFunction) => mutateFunction());
  };

  document.title = "Dashboard | SimplyPay";
  return (
    <React.Fragment>
      <div className="page-content">
        <main className="home__page">
          <DashboardAccount walletBalance={data1?.balance} isValidating={isValidating} mutate={mutateAll} />

          <React.Fragment>
            <div className="transactions__homeheader">
              <div className="transactions__homeheader--container">
                <span>Recent 5 Transactions</span>
                <Link to="/transactions" className="d-inline-block auth-logo">
                  <div className="transactions__homeheader--left">
                    <p>View All</p>
                  </div>
                </Link>
              </div>

              {isLoading ? <ListLoader /> : null}
            </div>

            <Card>
              <CardBody>
                <RecentTransactionsList
                  transactionsList={transactionsList}
                  isValidating={isTransactionListValidating}
                />
              </CardBody>
            </Card>
          </React.Fragment>

          {/* <Loader /> */}
          {/* )} */}
        </main>
      </div>
    </React.Fragment>
  );
}
