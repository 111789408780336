import React, { useState } from "react";
import { CountrySelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

export default function CountrySelectField({ setCountry }) {
  return (
    <div>
      <h6>Country</h6>
      <CountrySelect
        containerClassName="form-group"
        inputClassName=""
        onChange={(_country) => setCountry(_country)}
        onTextChange={(_txt) => console.log(_txt)}
        placeHolder="Select Country"
      />
    </div>
  );
}
