import React, { useState } from "react";
import { CountrySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

export default function StateSelectField({ country, currentState, setCurrentState }) {
  return (
    <div>
      <h6>State</h6>
      <StateSelect
        countryid={country?.id}
        containerClassName="form-group"
        inputClassName=""
        onChange={(_state) => setCurrentState(_state)}
        onTextChange={(_txt) => console.log(_txt)}
        defaultValue={currentState}
        placeHolder="Select State"
      />
    </div>
  );
}
